import React from "react";

import { BottomContent, Copyright, LegalLink, LegalLinks, SimpleFooterContent, SimpleFooterWrapper, SocialLink, SocialLinks, TopContent } from "./styles/SimpleFooter.styled";
import WGLogoWhite from '../../assets/icons/logo-white.svg'
import { SocialYouTube, SocialInstagram, SocialLinkedIn, SocialTwitter } from "../../utils/IconsList";

import { useStaticQuery, graphql, Link } from "gatsby";

const SimpleFooter = () => {
  const data = useStaticQuery(graphql`
    query SimpleFooterQuery {
      allContentfulGlobalFooter {
        nodes {
          id
          companyMeta {
            phoneNumber
            facebookUrl
            instagramUrl
            linkedInUrl
            twitterUrl
            youtubeUrl
          }
        }
      }
    }
  `)

  const footerData = data?.allContentfulGlobalFooter?.nodes[0]

  return (
    <SimpleFooterWrapper>
      <SimpleFooterContent>
        <TopContent>
          <Link to='/'>
            <img loading="lazy" className="footer--logo-icon" src={WGLogoWhite} alt='Workgrid' />
          </Link>
          <SocialLinks>
            {footerData?.companyMeta?.linkedInUrl && <SocialLink aria-label="Workgrid LinkedIn" href={footerData?.companyMeta?.linkedInUrl} target='_blank'><SocialLinkedIn /></SocialLink>}
            {footerData?.companyMeta?.twitterUrl && <SocialLink aria-label="Workgrid Twitter" href={footerData?.companyMeta?.twitterUrl} target='_blank'><SocialTwitter /></SocialLink>}
            {footerData?.companyMeta?.youtubeUrl && <SocialLink aria-label="Workgrid YouTube" href={footerData?.companyMeta?.youtubeUrl} target='_blank'><SocialYouTube /></SocialLink>}
            {footerData?.companyMeta?.instagramUrl && <SocialLink aria-label="Workgrid Instagram" href={footerData?.companyMeta?.instagramUrl} target='_blank'>< SocialInstagram /></SocialLink>}
          </SocialLinks>
        </TopContent>
        <BottomContent>
          <LegalLinks>
            <LegalLink to="/privacy-policy">Privacy policy</LegalLink>
            <LegalLink to="/terms-and-conditions">Terms and conditions</LegalLink>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
          </LegalLinks>
          <Copyright>{`© ${new Date().getFullYear()} Workgrid Software. All rights reserved.`}</Copyright>
        </BottomContent>
      </SimpleFooterContent>
    </SimpleFooterWrapper>
  )
}

export default SimpleFooter

import styled from "styled-components"

export const DesktopNavWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100px;
  background: ${props => props.theme?.background || "#FFF"};
  border-bottom: ${props => props.theme?.background || "1px solid #E4E9F1"};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  .nav--container {
    width: 100%;
    height: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cta-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .nav--left {
    .nav--logo {
      width: 170px;
      height: auto;
    }
  }
  .nav--right {
    button {
      padding: 8px 20px !important;
      height: auto !important;
      font-weight: bold;
      line-height: 27px !important;
      white-space: nowrap;
      max-width: 188px;
      min-width: fit-content !important;
      @media (min-width: 1280px) {
        min-width: 180px !important;
        padding: 8px 24px !important;
      }
    }
  }
  .nav--center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 992px) {
    padding: 0px 40px;
  }
  @media (max-width: 480px) {
    padding: 0px 20px;
  }
`
export const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  .nav--item-link {
    text-decoration: none;
    height: 100%;
    display: flex;
    transition: all 0.1s ease-in-out;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px;
    color: ${props => (props.theme?.theme === "dark" ? "#FFF" : "#1B1B1B")};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    .nav--item-link-arrow {
      margin-left: 8px;
      width: 11px;
      height: 6px;
      position: relative;
      img {
        width: 11px;
        opacity: 1;
        visibility: visible;
        position: absolute;
        transition: all 0.1s ease-in-out 0.1s;
        &.item-link-arrow-hover {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  &.nav--item-hovered {
    .nav--item-link {
      color: #002dc2;
      .nav--item-link-arrow {
        img {
          opacity: 0;
          visibility: hidden;

          &.item-link-arrow-hover {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .nav--item-list {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const NavItemList = styled.div`
  width: 100%;
  min-height: 250px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  background: #fff;
  position: absolute;
  left: 0;
  top: 100px;
  cursor: auto;
  border-bottom: 1px solid #e4e7ec;
  box-shadow: 0px 5px 16px rgba(45, 40, 77, 0.05);
  opacity: 0.5;
  visibility: hidden;
  transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    left: 0px;
    width: 100%;
    height: 10px;
    background-color: rgb(255, 255, 255);
    pointer-events: none;
  }
  .nav--item-list-wrapper {
    display: flex;
    width: 100%;
    .nav--item-list-content {
    }
    .item-menu--left {
      width: calc(max(50% - 600px + 240px, 270px));
      display: flex;
      justify-content: flex-end;
      padding: 30px 0 30px 30px;
      .nav--item-list-content-wrapper {
        width: 240px;
        height: fit-content;
        border-right: 1px solid #e4e7ec;
        padding: 0 34px 70px 0;
      }
      h6 {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        color: #1b1b1b;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #667085;
        margin: 10px 0 0;
      }
    }
    .item-menu--center {
      flex: 1;
      max-width: calc(100% - 540px);
      padding: 30px 34px 0;
      .nav--item-list-content-wrapper {
        display: flex;
        .nav--item-list-category {
          flex: 1;
          padding: 0 26px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          h6 {
            font-weight: 900;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #4d4d4d;
            padding-bottom: 20px;
            border-bottom: 1px solid #e4e7ec;
            margin: 0 0 30px;
          }
          &.no-title {
            margin-top: 75px;
          }
        }
        .nav--item-list-menu-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .nav-menu-item--wrapper {
            width: 50%;
            max-width: 270px;
          }
        }
      }
      .bottom-link {
        width: 100%;
        text-align: center;
        & button {
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #002dc2;
          text-decoration: none;
          padding: 8px 16px !important;
          height: 38px !important;
          &:hover {
            color: #5260ff;
          }
        }
      }
    }
    .item-menu--right {
      width: calc(max(50% - 600px + 240px, 270px));
      padding: 30px 30px 30px 0;
      background: #f9fafb;
      .nav--item-list-content-wrapper {
        width: 240px;
        padding-left: 34px;
      }
      .nav--item-other-list {
        border-bottom: 1px solid #e4e7ec;
        padding: 40px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
        .nav--item-other-title {
          display: block;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #4d4d4d;
          margin: 0 0 20px;
        }
        img {
          width: 100%;
          height: auto;
          margin: 0 0 10px;
        }
        h6 {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #1b1b1b;
          padding: 4px 0;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          color: #667085;
          margin: 0;
        }
        .nav--item-other-learn-more {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #002dc2;
          text-decoration: none;
          margin: 4px 0 0;
        }
        .nav--item-other-link {
          display: block;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #101828;
          text-decoration: none;
          margin: 0 0 8px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
`

export const NavMenuItemWrapper = styled.div`
  margin-bottom: 30px;
  .nav-menu-item--link {
    display: flex;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
    &.no-description {
      align-items: center;
    }
  }

  .nav-menu-item--icon {
    margin-right: 14px;
    img {
      width: 38px;
      height: 38px;
    }
  }
  .nav-menu-item--content {
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #1b1b1b;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      color: #667085;
      margin: 4px 0 0;
    }
  }
`

import React from "react"

import { NavMenuItemWrapper } from "../styles/DesktopNavigation.styled"

const NavMenuItem = ({ title, description, icon, url, key }) => (
  <NavMenuItemWrapper className="nav-menu-item--wrapper" key={key}>
    <a
      className={`nav-menu-item--link ${!description ? "no-description" : ""}`}
      href={url || "#"}
      aria-label={title}
    >
      <div className="nav-menu-item--icon">
        <img src={icon?.file?.url} alt={icon?.title} />
      </div>
      <div className="nav-menu-item--content">
        <span>{title}</span>
        <p>{description}</p>
      </div>
    </a>
  </NavMenuItemWrapper>
)

export default NavMenuItem

import React, { useState } from "react"
import { navigate } from "gatsby"
import { defaultResolveRichText } from "../../../utils/resolve-rich-text/defaultResolveRichText"
import { NavItemWrapper, NavItemList } from "../styles/DesktopNavigation.styled"

import OptimizedImage from "../../../utils/optimizedImage"

import { IconButton } from "../../../atoms"
import NavMenuItem from "../components/NavMenuItem"
import onKeyDown from "../../../utils/onKeyDown"

const NavItem = ({
  navContent,
  sidebarLinks,
  sidebarFeatured,
  index,
  theme,
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <NavItemWrapper
      className={`nav--item ${hovered ? "nav--item-hovered" : ""}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => setHovered(!hovered)}
      onKeyDown={e => onKeyDown(e, () => setHovered(!hovered))}
      tabIndex={0}
      aria-expanded={hovered}
      aria-label={`${hovered ? "Collapse" : "Expand"} ${navContent.title}`}
      key={index}
      theme={theme}
    >
      <a
        className="nav--item-link"
        href={navContent?.url}
        tabIndex={navContent?.url ? 0 : -1}
        onKeyDown={e => onKeyDown(e, () => navigate(navContent.url))}
        aria-label={
          navContent?.url
            ? `${navContent.url}`
            : `Empty dropdown menu parent link`
        }
      >
        {navContent.title}
        {(navContent?.menuCategories?.length > 0 ||
          navContent?.menuItems?.length > 0) && (
          <div className="nav--item-link-arrow">
            <img
              alt="navigation icon"
              src={
                theme?.theme === "dark"
                  ? "/icons/nav-arrow-down-white.svg"
                  : "/icons/nav-arrow-down.svg"
              }
            />
            <img
              alt="navigation Icon"
              className="item-link-arrow-hover"
              src={"/icons/nav-arrow-down-hover.svg"}
            />
          </div>
        )}
      </a>
      {(navContent?.menuCategories?.length > 0 ||
        navContent?.menuItems?.length > 0) && (
        <NavItemList className="nav--item-list">
          <div className="nav--item-list-wrapper">
            <div className="nav--item-list-content item-menu--left">
              <div className="nav--item-list-content-wrapper">
                <h6>{navContent?.title}</h6>
                <p>{navContent?.description}</p>
              </div>
            </div>
            <div className="nav--item-list-content item-menu--center">
              <div
                className={`nav--item-list-content-wrapper ${
                  navContent?.menuCategories?.length > 0
                    ? "item-menu--has-category"
                    : ""
                }`}
              >
                {navContent?.menuCategories?.length > 0 &&
                  navContent?.menuCategories?.map((categoryItem, index1) => (
                    <div
                      className={`nav--item-list-category ${
                        categoryItem?.hideTitle ? "no-title" : ""
                      }`}
                      key={index1}
                    >
                      {!categoryItem?.hideTitle && (
                        <h6>{categoryItem?.title}</h6>
                      )}
                      <div className="nav--item-list-category-content">
                        {categoryItem?.menuItems?.length > 0 &&
                          categoryItem?.menuItems?.map((menuItem, index2) => (
                            <NavMenuItem {...menuItem} key={index2} />
                          ))}
                      </div>
                    </div>
                  ))}
                {navContent?.menuItems?.length > 0 && (
                  <div className="nav--item-list-menu-content">
                    {navContent?.menuItems?.map((menuItem, index2) => (
                      <NavMenuItem {...menuItem} key={index2} />
                    ))}
                  </div>
                )}
              </div>
              {navContent?.bottomLink && (
                <div className="bottom-link">
                  <IconButton
                    ctaVariant={navContent.bottomLink.variant}
                    ctaLink={navContent?.bottomLink?.link}
                  >
                    {navContent.bottomLink?.label}
                  </IconButton>
                </div>
              )}
            </div>
            <div className="nav--item-list-content item-menu--right">
              <div className="nav--item-list-content-wrapper">
                <div className="nav--item-other-list">
                  <span className="nav--item-other-title">Features</span>
                  <OptimizedImage
                    alt="features section"
                    image={sidebarFeatured?.featuredImage?.gatsbyImageData}
                    src={sidebarFeatured?.featuredImage?.file?.url}
                  />
                  <h6>{sidebarFeatured?.heading}</h6>
                  {sidebarFeatured?.subheading && (
                    <p>{defaultResolveRichText(sidebarFeatured?.subheading)}</p>
                  )}
                  <a
                    className="nav--item-other-learn-more"
                    href={sidebarFeatured?.callsToAction[0]?.link}
                  >
                    {sidebarFeatured?.callsToAction[0]?.label}
                  </a>
                </div>
                <div className="nav--item-other-list">
                  <span className="nav--item-other-title">More</span>
                  {sidebarLinks &&
                    sidebarLinks?.map(link => {
                      return (
                        <a className="nav--item-other-link" href={link?.link}>
                          {link?.label}
                        </a>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </NavItemList>
      )}
    </NavItemWrapper>
  )
}

export default NavItem

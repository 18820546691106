import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const OptimizedImage = ({ src, image, alt, loading, className }) => {
  if (image) {
    return (
      <GatsbyImage
        className={className}
        alt={alt}
        loading={loading || "lazy"}
        image={image}
        placeholder="none"
      />
    )
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      loading={loading || "lazy"}
    />
  )
}

export default OptimizedImage
